import { Container } from "@material-ui/core";
import React from "react";

export default function PaymentFailed() {
  return (
    <div>
      <Container>
        <h1>Hosting suspended due to non-payment</h1>
      </Container>
    </div>
  );
}
