import {
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import FileUploader from "../../shared/FileUploader";
import Select from "react-select";
import { selectFieldProps, adPositions, textFieldProps } from "../../shared/constants";

const useStyles = makeStyles({
  root: {
    background: "#efefef",
  },
  media: {
    height: 250,
  },
});

export default function CreateAd({ open, close }) {
  const classes = useStyles();
  const { StreamCollection, createStandardDoc } = useDb();
  const categories = StreamCollection("categories");
  const [adImage, setAdImage] = useState();
  const [loading, setLoading] = useState(false);
  const [ad, setAd] = useState({});

  const handleClose = () => {
    setLoading(false);
    setAdImage(null);
    close();
  };

  const handleImage = (file) => {
    setAdImage(file.url);
    setAd({
      ...ad,
      imageurl: file.url,
    });
  };

  const handleSelect = (v, e) => {
    const array = v.map(i => i.value);
    setAd({
        ...ad,
        [e.name]: array
    });
  };

  const handleText = (e) => {
    setAd({
        ...ad,
        [e.target.name]: e.target.value
    })
  }

  console.log(ad);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await createStandardDoc("ad_banners", ad);
      handleClose();
    } catch (err) {
      setLoading(false);
      window.alert(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create Ad</DialogTitle>
      <DialogContent>
        <TextField 
            {...textFieldProps}
            label="Ad/Client Name"
            required
            onChange={handleText}
            name="title"
        />
        <Select
          {...selectFieldProps}
          className="tenx-select"
          options={categories}
          isMulti
          name="categories"
          placeholder="Feature on Category Pages ... (Optional)"
          onChange={handleSelect}
        />
        <Select
          {...selectFieldProps}
          className="tenx-select"
          options={adPositions}
          isMulti
          name="position"
          placeholder="Feature on Home Page Position: (Optional)"
          onChange={handleSelect}
        />
        {adImage && (
          <Card>
            <CardMedia image={adImage} className={classes.media} />
          </Card>
        )}
        <FileUploader sendFile={handleImage} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          startIcon={<Save />}
          disabled={loading || !adImage || !ad.title}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
