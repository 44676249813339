import { createTheme, ThemeProvider } from "@material-ui/core";
import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { DatabaseProvider } from "../contexts/DatabaseContext";
import CustomerRoutes from "../routes/CustomerRoutes";
import PublicRoutes from "../routes/PublicRoutes";
import AdminPage from "./admin/AdminPage";
import PaymentFailed from "./PaymentFailed";

// Set your theme colors and styles below. Details on Theming here: https://material-ui.com/customization/theming/#theming
const theme = createTheme({
  palette: {
    primary: {
      main: "#262626",
      contrastText: "#fff",
    },
    mainMenuText: "#fff",
  },
});

export default function Wrapper() {
  const { currentUser } = useAuth();
  return (
    <ThemeProvider theme={theme}>
      {/* {currentUser ? (
        <DatabaseProvider>
          {currentUser.admin ? <AdminPage /> : <CustomerRoutes />}
        </DatabaseProvider>
      ) : (
        <PublicRoutes />
      )} */}
      <PaymentFailed />
    </ThemeProvider>
  );
}
