import { Button, Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        margin: '10px 0'
    },
    btn: {
        margin: '15px 0'
    }
}))

export default function CustomerPage() {
    const classes = useStyles()
    const { logOut } = useAuth()

    const handleLogOut = async () => {
        try {
            await logOut()
        }
        catch(err) {
            console.log(err.message)
        }
    }

    return (
        <div>
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <Typography className={classes.title}>
                        Customer Page
                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className={classes.btn}
                        size="large"
                        type="submit"
                        onClick={handleLogOut}
                    >
                        Log Out
                    </Button>
                </div>
            </Container>
        </div>
    )
}
