import {
  AppBar,
  Badge,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import {
  AccountBox,
  Assessment,
  CheckCircleOutlineSharp,
  Commute,
  Contacts,
  ExpandLess,
  ExpandMore,
  Home,
  ImportContacts,
  Info,
  Menu,
  People,
} from "@material-ui/icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDb } from "../../contexts/DatabaseContext";
import { useAuth } from "../../contexts/AuthContext";
import AdminMenu from "./AdminMenu";
import AdminRoutes from "../../routes/AdminRoutes";
import logo from "../../img/logo.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: theme.palette.primary.main,
    borderBottom: "1px solid #e0e0e0",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icons: {
    color: "#fff",
    marginLeft: theme.spacing(2),
  },
  menuText: {
    color: theme.palette.mainMenuText,
    textDecoration: "none",
  },
  subMenuItem: {
    paddingLeft: 55,
  },
}));

export default function AdminPage(props) {
  const { currentUser } = useAuth();
  const { window } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <img src={logo} style={{ maxHeight: 50, marginTop: 5, marginLeft: 12 }} />
      <div className={classes.toolbar} />
      <Divider />
      <AdminMenu classes={classes} />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <AdminRoutes />
      </main>
    </div>
  );
}
