export const selectFieldProps = {
    menuPortalTarget: document.body,
    styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
  };
  
  export const textFieldProps = {
    variant: "outlined",
    fullWidth: true,
    style: { marginBottom: 10 },
  };

  export const adPositions = [
    { value: "hometop", label: "Home Top" },
    { value: "homecenter", label: "Home Center" },
    { value: "homebottom", label: "Home Bottom" },
  ]

  export const formatPhoneNumber = number => {
    let formattedPhoneNumber = number;

    formattedPhoneNumber = formattedPhoneNumber.replace(/ /g, "");

    if (formattedPhoneNumber.startsWith("0")) {
      formattedPhoneNumber = `+27${formattedPhoneNumber.slice(1, 10)}`;
    }

    if (formattedPhoneNumber.startsWith("+270")) {
      formattedPhoneNumber = `+27${formattedPhoneNumber.slice(4, 13)}`;
    }

    if (formattedPhoneNumber.length === 9) {
      formattedPhoneNumber = `+27${formattedPhoneNumber}`;
    }

    return formattedPhoneNumber;
};