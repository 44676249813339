import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Add, Delete, Image, Info, Save } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import BackLink from "../../shared/BackLink";
import { formatPhoneNumber, textFieldProps } from "../../shared/constants";
import FileUploader from "../../shared/FileUploader";
import AddCategoriesToSupplier from "./AddCategoriesToSupplier";

const useStyles = makeStyles({
  root: {
    background: "#efefef",
  },
  media: {
    height: 150,
  },
  logo: {
    height: 200,
    width: 200,
  },
});

export default function SupplierDetail() {
  const { supplierId } = useParams();
  const { getStandardDoc, updateStandardDoc, removeOfferingFromSupplier, addSupplierToSubCategories } = useDb();
  const [supplier, setSupplier] = useState();
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [addCatOpen, setAddCatOpen] = useState(false);
  const [selectedSubCatIds, setSelectedSubCatIds] = useState([]);
  const classes = useStyles();

  const loadSupplier = async () => {
    const s = await getStandardDoc("suppliers", supplierId);
    setSupplier(s);
  };

  useEffect(() => {
    const unsubscribe = loadSupplier();
    return unsubscribe;
  }, [supplierId]);

  const handleChange = (e) => {
    setUpdated(true);
    setSupplier({
      ...supplier,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = async () => {
    if (supplier.whatsappNumber) {
      setSupplier({
        whatsappNumber: formatPhoneNumber(supplier.whatsappNumber),
      })
    }
    if(supplier.number)
    setSupplier({
      number: formatPhoneNumber(supplier.number)
    })
    setLoading(true);
    try {
      await updateStandardDoc("suppliers", supplier.id, supplier);
      if(selectedSubCatIds.length > 0) {
        await addSupplierToSubCategories(selectedSubCatIds, supplierId);
      }
      setLoading(false);
      setSnackOpen(true);
      setUpdated(false);
      loadSupplier();
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
    }
  };

  const handleImage = (file) => {
    setSupplier({
      ...supplier,
      imageurl: file.url,
    });
    setUpdated(true);
  };

  const handleSliderImage = (file) => {
    setSupplier({
      ...supplier,
      image_gallery: supplier.image_gallery
        ? [...supplier.image_gallery, file.url]
        : [file.url],
    });
    setUpdated(true);
  };

  const handleLogo = (file) => {
    setSupplier({
      ...supplier,
      logo_url: file.url,
    });
    setUpdated(true);
  };

  const removeSliderImage = (img) => {
    const array = supplier.image_gallery;
    const index = array.indexOf(img);
    const newArray = array.splice(index, 1);
    setSupplier({
      ...supplier,
      image_gallery: newArray,
    });
    setUpdated(true);
  };

  const handleCatRemove = async (offering) => {
    try {
      const confirm = window.confirm("Are you sure you want to remove this category?")
      if (confirm) {
        await removeOfferingFromSupplier(supplier.id, offering);
        loadSupplier();
      }
    }
    catch (err) {
      window.alert(err.message)
    }
  }

  const receiveCategories = async (data) => {
    setSupplier({
      ...supplier,
      offerings: [...supplier.offerings, ...data.offerings],
    });
    // setCategories([...categories, ...data.offerings]);
    setSelectedSubCatIds([...selectedSubCatIds, ...data.subCatIds]);
    setUpdated(true);
  };

  return !supplier ? (
    <LinearProgress />
  ) : (
    <div>
      <h1>{supplier.title}</h1>
      <BackLink overridePath="/suppliers" />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Supplier Details" avatar={<Info />} />
              <CardContent>
                <TextField
                  {...textFieldProps}
                  label="Supplier Title"
                  value={supplier.title}
                  name="title"
                  onChange={handleChange}
                />
                <TextField
                  {...textFieldProps}
                  label="Supplier Subtitle"
                  value={supplier.subtitle}
                  name="subtitle"
                  onChange={handleChange}
                />
                <TextField
                  {...textFieldProps}
                  label="Supplier Address"
                  value={supplier.address}
                  name="address"
                  onChange={handleChange}
                />
                <TextField
                  {...textFieldProps}
                  label="Description"
                  value={supplier.description}
                  name="description"
                  onChange={handleChange}
                />
                <TextField
                  {...textFieldProps}
                  label="Email"
                  value={supplier.email}
                  name="email"
                  onChange={handleChange}
                />
                <Avatar src={supplier.logo_url} className={classes.logo} />
                <FileUploader label="Change LOGO" sendFile={handleLogo} />
              </CardContent>
              <CardActions>
                {updated && (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    onClick={handleUpdate}
                    disabled={loading}
                  >
                    Save Changes
                  </Button>
                )}
              </CardActions>
            </Card>
            <br />
            <Card>
              <CardHeader title="Categories & Sub-Categories" avatar={<Info />} />
              <CardContent>
                {supplier.offerings && supplier.offerings.map((offering, index) => (
                  <Chip key={index} label={offering} style={{ marginBottom: 5, marginRight: 5 }} onDelete={() => handleCatRemove(offering)} />
                ))}
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => setAddCatOpen(true)}
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                >
                  Add Categories
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Supplier Details" avatar={<Info />} />
              <CardContent>
                <TextField
                  {...textFieldProps}
                  label="Phone Number"
                  value={supplier.number}
                  name="number"
                  onChange={handleChange}
                />
                <TextField
                  {...textFieldProps}
                  label="Whatsapp Number"
                  value={supplier.whatsappNumber}
                  name="whatsappNumber"
                  onChange={handleChange}
                />
                <TextField
                  {...textFieldProps}
                  label="Website"
                  value={supplier.website}
                  name="website"
                  onChange={handleChange}
                />
                <Card className={classes.root}>
                  <CardHeader
                    title="Image Slider"
                    avatar={
                      <Avatar>
                        <Image />
                      </Avatar>
                    }
                    action={
                      updated && (
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<Save />}
                          onClick={handleUpdate}
                          disabled={loading}
                        >
                          Save Changes
                        </Button>
                      )
                    }
                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      {supplier.image_gallery &&
                        supplier.image_gallery.map((i) => (
                          <Grid key={i} item xs={6}>
                            <Card>
                              <CardHeader
                                action={
                                  <IconButton
                                    onClick={() => removeSliderImage(i)}
                                  >
                                    <Delete />
                                  </IconButton>
                                }
                              />
                              <CardMedia
                                image={i}
                                className={classes.media}
                              />
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <FileUploader
                      label="Add Header Image to Slider"
                      sendFile={handleSliderImage}
                    />
                  </CardActions>
                </Card>
                <br />
                <Card className={classes.root}>
                  <CardHeader
                    title="Header Image"
                    avatar={
                      <Avatar>
                        <Image />
                      </Avatar>
                    }
                  />
                  <CardMedia
                    image={supplier.imageurl}
                    className={classes.media}
                  />
                  <CardActions>
                    <FileUploader
                      label="Change Header Image"
                      sendFile={handleImage}
                    />
                  </CardActions>
                </Card>
              </CardContent>
              <CardActions>
                {updated && (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    onClick={handleUpdate}
                    disabled={loading}
                  >
                    Save Changes
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        </Grid>

      </Container>

      <Snackbar
        open={snackOpen}
        autoHideDuration={4000}
        message="Changes Saved!"
        onClose={() => setSnackOpen(false)}
      />

      <AddCategoriesToSupplier
        open={addCatOpen}
        close={() => setAddCatOpen(false)}
        sendData={receiveCategories}
      />
    </div>
  );
}
