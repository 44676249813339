import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
    // Paste your Firebase config here
    apiKey: "AIzaSyAB0IKrgIBuWlputC8Syx9hg8TVjANEqN8",
    authDomain: "xdirect-6aff3.firebaseapp.com",
    projectId: "xdirect-6aff3",
    storageBucket: "xdirect-6aff3.appspot.com",
    messagingSenderId: "749695911103",
    appId: "1:749695911103:web:a5d01b4122ce9a284db20a",
    measurementId: "G-D3336EF3LR"
}

const app = firebase.initializeApp(config);

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const firestoreFunctions = firebase.firestore;
export const fieldValue = firebase.firestore.FieldValue;

export default app;