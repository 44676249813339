import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import BackLink from "../../shared/BackLink";
import { textFieldProps } from "../../shared/constants";
import FileUploader from "../../shared/FileUploader";

const useStyles = makeStyles({
  media: {
    height: 350,
  },
});

export default function AdsDetail() {
  const { adId } = useParams();
  const classes = useStyles();
  const { getStandardDoc, updateStandardDoc } = useDb();
  const [ad, setAd] = useState();
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const retrieveAd = async () => {
    const ad = await getStandardDoc("ad_banners", adId);
    setAd(ad);
  };

  useEffect(() => {
    const unsubscribe = retrieveAd();
    return unsubscribe;
  }, [adId]);

  const handleText = (e) => {
    setAd({
      ...ad,
      [e.target.name]: e.target.value,
    });
    setUpdated(true);
  };

  const receiveFile = (file) => {
    setAd({
      ...ad,
      imageurl: file.url,
    });
    setUpdated(true);
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      await updateStandardDoc("ad_banners", adId, ad);
      setSnackOpen(true);
      setUpdated(false);
      setLoading(false);
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Ad Detail</h1>
      <BackLink />
      {!ad ? (
        <LinearProgress />
      ) : (
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <CardMedia className={classes.media} image={ad.imageurl} />
                  <FileUploader sendFile={receiveFile} />
                </CardContent>
                {updated && (
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Save />}
                      onClick={handleUpdate}
                      disabled={loading}
                    >
                      Save Changes
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <TextField
                    onChange={handleText}
                    name={"ad_link"}
                    {...textFieldProps}
                    label="Ad Link"
                    type="url"
                    value={ad.ad_link}
                  />
                  <TextField
                    onChange={handleText}
                    name={"title"}
                    {...textFieldProps}
                    label="Ad Title"
                    value={ad.title}
                  />
                </CardContent>
                {updated && (
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Save />}
                      onClick={handleUpdate}
                      disabled={loading}
                    >
                      Save Changes
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Grid>
          </Grid>

          <Snackbar
            message="Ad updated successfully."
            open={snackOpen}
            autoHideDuration={3000}
            onClose={() => setSnackOpen(false)}
          />
        </Container>
      )}
    </div>
  );
}
