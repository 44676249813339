import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  async function logOut() {
    return await auth.signOut().then(() => {
      window.location.reload();
    });
  }

  function logIn(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function forgotPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  async function signUp(email, password) {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCred) => {
        // You can capture other details when the user signs up, like name and surname and pass them to the sign up function,
        // by adding them to the object passed below as the second argument. Remember to add them as parameters to the signUp function itself
        createUserRecord(userCred.user.uid, { email });
      });
  }

  function createUserRecord(uid, data) {
    return db.collection("users").doc(uid).set({
      email: data.email,
      uid,
      created_time: new Date(),
    });
  }

  async function getUserRecord(uid) {
    return await db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        return doc.data();
      });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await getUserRecord(user.uid);
        setCurrentUser({ ...user, ...userDoc });
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signUp,
    logOut,
    logIn,
    forgotPassword,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
